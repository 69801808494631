<template>
  <div class="w-full flex my-4">
    <input
      v-if="isEditing"
      class="font-bold text-xl px-2 outline-none border-b border-black"
      type="text"
      v-model="item.title"
      :class="{error: !item.title}"
      placeholder="Can't be empty"
      >
    <div v-else class="font-bold text-xl ml-3 mr-5">{{ item.title }}</div>
    <!-- Add element to this section -->
    <span
      @click="$emit('add')"
      class="rounded-lg bg-green-400 hover:bg-green-500 cursor-pointer text-white py-1 px-3 mx-2"
    >
      <span class="font-bold">+</span>
      Add data
    </span>
    <!-- Edit section name -->
    <span
      v-if="!isEditing"
      @click="() => { isEditing = true; }"
      class="bg-blue-500 hover:bg-blue-600 cm-button mx-2"
    >
      <font-awesome-icon icon="pen" />
    </span>
    <!-- Confirm edit -->
    <span
      v-else
      @click="changeCategory"
      class="bg-green-500 hover:bg-green-600 cm-button mx-2"
    >
      <b class="text-xl">✓</b>
    </span>
    <!-- delete -->
    <span
      @click="$emit('delete')"
      class="bg-red-500 hover:bg-red-600 cm-button mx-2"
    >
      <font-awesome-icon icon="trash" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableSection',
  props: {
    item: Object,
    requests: Object
  },
  data () {
    return {
      isEditing: false
    }
  },
  methods: {
    async changeCategory () {
      await this.requests.changeCategory(this.item._id, this.item.title)
      this.$emit('getData')
      this.isEditing = false
    }
  }
}
</script>

<style>
</style>
