<template>
  <div class="pl-5 py-4 flex flex-wrap h-full">
    <div class="w-full h-1/3 text-xl">Delete Data</div>
    <div class="w-full h-1/3 text-lg">
      Are you sure you wanna delete this data?
    </div>
    <div class="w-full h-1/3 flex items-end justify-around">
      <span
        @click="deleteData"
        class="
          flex
          items-center
          h-4/5
          rounded-lg
          cursor-pointer
          px-4
          bg-red-500
          text-white
        "
        >Delete</span
      >
      <span
        @click="$emit('close')"
        class="
          flex
          items-center
          h-4/5
          rounded-lg
          cursor-pointer
          px-4
          border border-black
        "
        >Cancel</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    requests: Object,
    isCategory: Boolean
  },
  methods: {
    async deleteData () {
      try {
        this.isCategory ? await this.requests.deleteCategory(this.item) : await this.requests.deleteOne(this.item)
        this.$store.commit('setChanged', true)
        this.$emit('close')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
</style>
