<template>
  <span class="relative Array text-center w-full flex" >
    <span class="w-full" v-if="array && array.length" >
      {{ header }}
      <div v-if="array.length > 1" class="absolute h-auto w-full border border-gray-400 bg-white top-0 left-0 z-10 p-2 rounded-lg hidden " >
        <ul v-if="isObjectArray">
          <li v-for="(j, m) in array" :key="m">
            {{ m + 1 + ". " + array[m][keys[type]] }}
          </li>
        </ul>
        <ul v-else>
          <li v-for="(j, m) in array" :key="m">
            {{ m + 1 + ". " + array[m] }}
          </li>
        </ul>
      </div>
    </span>
    <span v-if="array && array.length > 1" class="text-gray-500" >▼</span>
  </span>
</template>

<script>
export default {
  props: {
    array: Array,
    type: String
  },
  data () {
    return {
      keys: {
        subLinks: 'name',
        links: 'title',
        categories: 'name',
        emails: '',
        area: ''

      }
    }
  },
  computed: {
    isObjectArray: function () {
      return typeof this.array[0] !== 'string'
    },
    header: function () {
      if (this.isObjectArray) { return this.array[0][this.keys[this.type]] }
      return this.array[0]
    }
  }
}
</script>

<style>
</style>
