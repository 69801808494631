<template>
  <div class="flex justify-center flex-wrap">
    <div class="w-full relative mr-2 mt-1 mb-5">
      <span
        @click="$emit('close')"
        class="
          absolute
          top-0
          right-0
          text-4xl
          cursor-pointer
          text-gray-500
          hover:text-red-500
        "
      >
        🞮
      </span>
    </div>
    <div class="pt-4 px-10 flex flex-wrap justify-between">
      <label
        class="relative flex flex-wrap items-center justify-between py-5"
        v-for="i in keys.length"
        :key="i"
        :for="`key-${i}`"
        :class="{
          error: errors[keys[i - 1]],
          'w-full': isFullWidth(keys[i - 1]) || isMarkDown(keys[i - 1]),
          'w-5/12': !(isFullWidth(keys[i - 1]) || isMarkDown(keys[i - 1])),
        }"
      >
        <span class="text-gray-400">
          {{ _.capitalize(keys[i - 1]) }}
        </span>
        <!-- show upload button if its image or logo or icon -->
        <label
          @dragover.prevent
          @drop.prevent
          v-if="
            (keys[i - 1] == 'image' ||
              keys[i - 1] == 'logo' ||
              keys[i - 1] == 'icon' ||
              keys[i - 1] == 'banner') &&
            $store.state.modalState.type != 'inspect'
          "
          :for="`upload-${i}`"
          class="border-2 border-black w-full text-center"
        >
          <input
            type="file"
            :id="`upload-${i}`"
            class="hidden"
            @change="changeFile($event, 'change', keys[i - 1])"
          />
          <span
            @drop="changeFile($event, 'drop', keys[i - 1])"
            class="font-bold h-full w-full block"
            >Press to upload <br />
            or drag a file</span
          >
        </label>
        <!-- show markdown for certain keys -->
        <quill-editor
          v-else-if="
            isMarkDown(keys[i - 1]) && $store.state.modalState.type != 'inspect'
          "
          v-model="item[keys[i - 1]]"
        />
        <!-- show array grid for links-->
        <div class="w-full pl-2 pt-2" v-else-if="keys[i - 1] == 'links'">
          <div v-if="item[keys[i - 1]] && item[keys[i - 1]].length">
            <div class="flex">
              <div class="w-full flex flex-wrap justify-between mr-3">
                <span class="text-gray-500">Title</span>
                <span class="text-gray-500">URL</span>
                <span class="text-gray-500">isExternal</span>
              </div>
              <span
                :class="{
                  invisible: $store.state.modalState.type == 'inspect',
                }"
                class="text-gray-500"
                >delete</span
              >
            </div>
            <div
              class="w-full flex flex-wrap justify-between"
              v-for="(j, m) in item[keys[i - 1]]"
              :key="m"
            >
              <label
                class="
                  relative
                  flex flex-wrap
                  items-center
                  justify-between
                  pb-5
                "
                :class="{ error: errors[keys[i - 1] + '-' + m + '-title'] }"
              >
                <input
                  class="border-b border-gray-500 py-2 outline-none"
                  type="text"
                  :id="`subKey-${j}-1`"
                  v-model="j.title"
                  :disabled="!isEditable"
                />
                <span
                  v-if="errors[keys[i - 1] + '-' + m + '-title']"
                  class="absolute bottom-0 left-0 h-5 text-sm"
                >
                  This field is required
                </span>
              </label>
              <label
                class="
                  relative
                  flex flex-wrap
                  items-center
                  justify-between
                  pb-5
                "
                :class="{ error: errors[keys[i - 1] + '-' + m + '-url'] }"
              >
                <input
                  class="border-b border-gray-500 py-2 outline-none"
                  type="text"
                  :id="`subKey-${j}-2`"
                  v-model="j.url"
                  :disabled="!isEditable"
                />
                <span
                  v-if="errors[keys[i - 1] + '-' + m + '-url']"
                  class="absolute bottom-0 left-0 h-5 text-sm"
                >
                  This field is required
                </span>
              </label>
              <label
                :for="`isExternal-${m}`"
                class="checkboxContainer font-bold"
              >
                <input
                  type="checkbox"
                  :id="`isExternal-${m}`"
                  v-model="j.isExternal"
                  :value="j.isExternal"
                  :disabled="!isEditable"
                />
                <span class="checkmark"></span>
              </label>
              <div class="h-12 w-12 flex justify-center items-center">
                <span
                  v-if="$store.state.modalState.type != 'inspect'"
                  @click="deleteSubArrayItem($event, keys[i - 1], m)"
                  class="
                    flex
                    items-center
                    justify-around
                    box-content
                    h-4
                    w-4
                    bg-red-500
                    hover:bg-red-600
                    text-sm text-white
                    p-2
                    rounded-md
                    cursor-pointer
                  "
                >
                  <font-awesome-icon icon="trash" />
                </span>
              </div>
            </div>
          </div>
          <div class="mt-4 w-full flex justify-center">
            <span
              v-if="$store.state.modalState.type != 'inspect'"
              @click="addSubArrayItem($event, keys[i - 1])"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                rounded-lg
                py-1
                pl-2
                pr-3
                cursor-pointer
              "
            >
              <b class="text-lg">+ </b>Add link
            </span>
          </div>
        </div>
        <!-- <ArrayGrid
          v-else-if="keys[i - 1] == 'links'"
          :array="item[keys[i - 1]]"
          :isEditable="isEditable"
          :type="keys[i - 1]"
          ref="grid"
          @delete="deleteSubArrayItem"
          @add="addSubArrayItem"
        /> -->
        <!-- show array grid for sublinks -->
        <div class="w-full pl-2 pt-2" v-else-if="keys[i - 1] == 'subLinks'">
          <div v-if="item[keys[i - 1]] && item[keys[i - 1]].length">
            <div class="flex justify-between">
              <span class="text-gray-500">Name</span>
              <span class="text-gray-500">URL</span>
              <span
                :class="{
                  invisible: $store.state.modalState.type == 'inspect',
                }"
                class="text-gray-500"
              >
                Actions
              </span>
            </div>
            <div
              class="w-full flex flex-wrap justify-between"
              v-for="(j, m) in item[keys[i - 1]]"
              :key="m"
            >
              <label
                class="
                  relative
                  flex flex-wrap
                  items-center
                  justify-between
                  pb-5
                "
                :class="{ error: errors[keys[i - 1] + '-' + m + '-name'] }"
              >
                <input
                  class="border-b border-gray-500 py-2 outline-none"
                  type="text"
                  :id="`subKey-${j}-1`"
                  v-model="j.name"
                  :disabled="!isEditable"
                />
                <span
                  v-if="errors[keys[i - 1] + '-' + m + '-name']"
                  class="absolute bottom-0 left-0 h-5 text-sm"
                >
                  This field is required
                </span>
              </label>
              <label
                class="
                  relative
                  flex flex-wrap
                  items-center
                  justify-between
                  pb-5
                "
                :class="{ error: errors[keys[i - 1] + '-' + m + '-url'] }"
              >
                <input
                  class="border-b border-gray-500 py-2 outline-none"
                  type="text"
                  :id="`subKey-${j}-2`"
                  v-model="j.url"
                  :disabled="!isEditable"
                />
                <span
                  v-if="errors[keys[i - 1] + '-' + m + '-url']"
                  class="absolute bottom-0 left-0 h-5 text-sm"
                >
                  This field is required
                </span>
              </label>
              <div class="h-12 w-12 flex justify-center items-center gap-1">
                <span
                  v-if="$store.state.modalState.type != 'inspect'"
                  @click="addSubSubArrayItem($event, keys[i - 1], m)"
                  class="
                    flex
                    items-center
                    justify-around
                    box-content
                    h-4
                    w-4
                    bg-green-500
                    hover:bg-green-600
                    text-sm text-white
                    p-2
                    rounded-md
                    cursor-pointer
                  "
                >
                  <font-awesome-icon icon="plus" />
                </span>
                <span
                  v-if="$store.state.modalState.type != 'inspect'"
                  @click="deleteSubArrayItem($event, keys[i - 1], m)"
                  class="
                    flex
                    items-center
                    justify-around
                    box-content
                    h-4
                    w-4
                    bg-red-500
                    hover:bg-red-600
                    text-sm text-white
                    p-2
                    rounded-md
                    cursor-pointer
                  "
                >
                  <font-awesome-icon icon="trash" />
                </span>
              </div>
              <div
                v-for="(k, l) in j.subLinks"
                :key="l"
                class="w-full flex flex-wrap justify-between"
              >
                <span>-</span>
                <label
                  class="
                    relative
                    flex flex-wrap
                    items-center
                    justify-between
                    pb-5
                  "
                  :class="{
                    error: errors[keys[i - 1] + '-' + m + '-' + 'l' + '-name'],
                  }"
                >
                  <input
                    class="border-b border-gray-500 py-2 outline-none"
                    type="text"
                    :id="`subKey-${k}-1`"
                    v-model="k.name"
                    :disabled="!isEditable"
                  />
                  <span
                    v-if="errors[keys[i - 1] + '-' + m + '-' + 'l' + '-name']"
                    class="absolute bottom-0 left-0 h-5 text-sm"
                  >
                    This field is required
                  </span>
                </label>
                <label
                  class="
                    relative
                    flex flex-wrap
                    items-center
                    justify-between
                    pb-5
                  "
                  :class="{
                    error: errors[keys[i - 1] + '-' + m + '-' + 'l' + '-url'],
                  }"
                >
                  <input
                    class="border-b border-gray-500 py-2 outline-none"
                    type="text"
                    :id="`subKey-${k}-2`"
                    v-model="k.url"
                    :disabled="!isEditable"
                  />
                  <span
                    v-if="errors[keys[i - 1] + '-' + m + '-' + 'l' + '-url']"
                    class="absolute bottom-0 left-0 h-5 text-sm"
                  >
                    This field is required
                  </span>
                </label>
                <div class="h-12 w-12 flex justify-center items-center">
                  <span
                    v-if="$store.state.modalState.type != 'inspect'"
                    @click="deleteSubSubArrayItem($event, keys[i - 1], m, l)"
                    class="
                      flex
                      items-center
                      justify-around
                      box-content
                      h-4
                      w-4
                      bg-red-500
                      hover:bg-red-600
                      text-sm text-white
                      p-2
                      rounded-md
                      cursor-pointer
                    "
                  >
                    <font-awesome-icon icon="trash" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 w-full flex justify-center">
            <span
              v-if="$store.state.modalState.type != 'inspect'"
              @click="addSubArrayItem($event, keys[i - 1])"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                rounded-lg
                py-1
                pl-2
                pr-3
                cursor-pointer
              "
            >
              <b class="text-lg">+ </b>Add link
            </span>
          </div>
        </div>
        <!-- show array -->
        <div
          class="w-full pl-2 pt-2"
          v-else-if="keys[i - 1] == 'subAreas' || keys[i - 1] == 'emails'"
        >
          <div v-if="item[keys[i - 1]] && item[keys[i - 1]].length">
            <div class="flex justify-between">
              <span class="text-gray-500">Title</span>
              <span
                :class="{
                  invisible: $store.state.modalState.type == 'inspect',
                }"
                class="text-gray-500"
                >delete</span
              >
            </div>
            <div
              class="w-full flex justify-between"
              v-for="j in item[keys[i - 1]].length"
              :key="j"
            >
              <label
                class="
                  flex-grow
                  relative
                  flex flex-wrap
                  items-center
                  justify-between
                  mr-4
                  pb-5
                "
                :class="{ error: errors[keys[i - 1] + '-' + (j - 1)] }"
              >
                <input
                  class="w-full border-b border-gray-500 py-2 outline-none"
                  type="text"
                  :id="`subKey-${j}-1`"
                  v-model="item[keys[i - 1]][j - 1]"
                  :disabled="!isEditable"
                />
                <span
                  v-if="errors[keys[i - 1] + '-' + (j - 1)]"
                  class="absolute bottom-0 left-0 h-5 text-sm"
                >
                  This field is required
                </span>
              </label>
              <div class="h-12 w-12 flex justify-center items-center">
                <span
                  v-if="$store.state.modalState.type != 'inspect'"
                  @click="deleteSubArrayItem($event, keys[i - 1], j - 1)"
                  class="
                    flex
                    items-center
                    justify-around
                    box-content
                    h-4
                    w-4
                    bg-red-500
                    hover:bg-red-600
                    text-sm text-white
                    p-2
                    rounded-md
                    cursor-pointer
                  "
                >
                  <font-awesome-icon icon="trash" />
                </span>
              </div>
            </div>
          </div>
          <span v-else>No data here</span>
          <div class="mt-4 w-full flex justify-center">
            <span
              v-if="$store.state.modalState.type != 'inspect'"
              @click="addStringSubArrayItem(keys[i - 1])"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                rounded-lg
                py-1
                pl-2
                pr-3
                cursor-pointer
              "
            >
              <b class="text-lg">+ </b>Add
              {{
                _.capitalize(keys[i - 1].substring(0, keys[i - 1].length - 1))
              }}
            </span>
          </div>
        </div>
        <!-- DatePicker -->
        <div
          v-else-if="
            keys[i - 1] === 'createdAt' &&
            $store.state.modalState.type !== 'inspect'
          "
          class="mt-2 w-full"
          :class="{
            'pointer-events-none': $store.state.modalState.type == 'inspect',
          }"
        >
          <v-date-picker is-expanded mode="dateTime" v-model="item.createdAt" />
        </div>
        <div
          v-else-if="keys[i - 1] === 'startDate'"
          class="mt-2 w-full"
          :class="{
            'pointer-events-none': $store.state.modalState.type == 'inspect',
          }"
        >
          <v-date-picker is-expanded v-model="dates" is-range />
        </div>
        <!-- select for vacancy type -->
        <label
          :for="`key${keys[i - 1]}`"
          class="w-full"
          v-else-if="keys[i - 1] == 'vacancyType'"
        >
          <v-select
            :id="`key${keys[i - 1]}`"
            class="w-full"
            :options="['job', 'internship']"
            v-model="item.vacancyType"
            :disabled="!isEditable"
          />
          <span
            v-if="errors[keys[i - 1]]"
            class="absolute bottom-0 left-0 h-5 text-sm"
          >
            This field is required
          </span>
        </label>
        <!-- multiselect for categories -->
        <label
          :for="`key${keys[i - 1]}`"
          class="w-full"
          v-else-if="keys[i - 1] == 'categories'"
        >
          <v-select
            :id="`key${keys[i - 1]}`"
            class="w-full"
            multiple
            label="name"
            :options="options"
            :reduce="(category) => category._id"
            v-model="item.categoryId"
            :disabled="!isEditable"
          />
          <span
            v-if="errors[keys[i - 1]]"
            class="absolute bottom-0 left-0 h-5 text-sm"
          >
            This field is required
          </span>
        </label>
        <!-- show text area for bigger texts -->
        <textarea
          rows="4"
          v-else-if="isFullWidth(keys[i - 1])"
          class="
            border-b border-gray-500
            py-2
            w-full
            outline-none
            bg-gray-50
            p-3
          "
          type="text"
          :id="`key-${i}`"
          v-model="item[keys[i - 1]]"
          :disabled="!isEditable"
        />
        <!-- show basic input -->
        <input
          v-else
          class="border-b border-gray-500 py-2 w-full outline-none"
          type="text"
          :id="`key-${i}`"
          v-model="item[keys[i - 1]]"
          :disabled="!isEditable"
        />
        <span
          v-if="errors[keys[i - 1]]"
          class="absolute bottom-0 left-0 h-5 text-sm"
        >
          This field is required
        </span>
      </label>
    </div>
    <span
      v-if="$store.state.modalState.type == 'add'"
      class="w-full mt-4 mb-8 flex justify-center"
    >
      <span
        @click="addData"
        class="
          rounded-lg
          bg-green-500
          hover:bg-green-600
          py-2
          px-5
          cursor-pointer
          text-white
        "
      >
        Add
      </span>
    </span>
    <span
      v-if="$store.state.modalState.type == 'edit'"
      class="w-full mt-4 mb-8 flex justify-center"
    >
      <span
        @click="changeData"
        class="
          rounded-lg
          bg-blue-500
          hover:bg-blue-600
          py-2
          px-5
          cursor-pointer
          text-white
        "
      >
        Submit
      </span>
    </span>
  </div>
</template>

<script>
import _ from 'lodash'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { HTTP } from '@/axios/axios'
import { quillEditor } from 'vue-quill-editor'
// import ArrayGrid from '@/components/modalComponents/ArrayGrid.vue'

export default {
  name: 'detailView',
  data () {
    return {
      errors: {},
      files: {
        image: null,
        logo: null,
        icon: null,
        banner: null
      },
      options: [],
      dates: {
        start: new Date(),
        end: new Date()
      }
    }
  },
  props: {
    isEditable: Boolean,
    item: Object,
    keys: Array,
    lang: String,
    requests: Object
  },
  components: {
    quillEditor
    // ArrayGrid
  },
  methods: {
    getCategories (route) {
      HTTP.get(`/${route}-category`, {
        headers: { 'accept-language': this.lang }
      })
        .then((res) => {
          this.options = res.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    //
    deleteSubArrayItem (e, subArrName, index) {
      this.item[subArrName].splice(index, 1)
      delete this.errors[subArrName + '-' + index]
      delete this.errors[subArrName + '-' + index + '-name']
      delete this.errors[subArrName + '-' + index + '-title']
      delete this.errors[subArrName + '-' + index + '-url']
      this.$forceUpdate()
    },
    deleteSubSubArrayItem (e, subArrName, index, subIndex) {
      this.item[subArrName][index].subLinks.splice(subIndex, 1)
      delete this.errors[subArrName + '-' + index + '-' + subIndex]
      delete this.errors[subArrName + '-' + index + '-' + subIndex + '-name']
      delete this.errors[subArrName + '-' + index + '-' + subIndex + '-url']
      this.$forceUpdate()
    },
    // merge this function with the one under this
    addStringSubArrayItem (subArrName) {
      this.item[subArrName]
        ? this.item[subArrName].push('')
        : (this.item[subArrName] = [''])
      this.$forceUpdate()
    },
    //
    addSubArrayItem (e, subArrName) {
      this.item[subArrName]
        ? this.item[subArrName].push({})
        : (this.item[subArrName] = [{}])
      this.$forceUpdate()
    },
    addSubSubArrayItem (e, subArrName, index) {
      this.item[subArrName][index].subLinks
        ? this.item[subArrName][index].subLinks.push({})
        : (this.item[subArrName][index].subLinks = [{}])
      this.$forceUpdate()
    },
    // checks if element shoould take more width like text area
    isFullWidth (k) {
      const fullWidth = [
        'links',
        'subAreas',
        'emails',
        'subLinks',
        'shortDescription',
        'story',
        'biography',
        'content',
        'description',
        'startDate'
      ]
      return fullWidth.indexOf(k) !== -1
    },
    // checks if the field should be markdown
    isMarkDown (k) {
      if (
        this.$route.name === 'CustomSearch' ||
        this.$route.name === 'Zigzags' ||
        this.$route.name === 'Parallax' ||
        this.$route.name === 'Titles' ||
        this.$route.name === 'ListContent' ||
        this.$route.name === 'Legacy'
      ) {
        return false
      }
      const markDowns = ['biography', 'content', 'description']
      return markDowns.indexOf(k) !== -1
    },
    needValidation (key) {
      return (
        key === 'titleLink' ||
        key === 'order' ||
        key === 'buttonText' ||
        key === 'buttonUrl' ||
        key === 'startDate' ||
        key === 'createdAt' ||
        (this.$route.name === 'ListContent' && key === 'description')
      )
    },
    // handles file upload on frontend
    changeFile (e, selectType, imageType) {
      selectType === 'drop'
        ? (this.files[imageType] = e.dataTransfer.files[0])
        : (this.files[imageType] = e.target.files[0])
    },
    // checks if form can be submitted
    checkValidation () {
      // const subArrays = ['links', 'subLinks']
      const subStringArrays = ['emails', 'subAreas']
      for (const key of this.keys) {
        if (
          key === 'image' ||
          key === 'logo' ||
          key === 'icon' ||
          key === 'banner'
        ) {
          if (
            this.$route.name !== 'ListContent' &&
            this.$route.name !== 'Faq'
          ) {
            !this.files[key] && !this.item[key]
              ? (this.errors[key] = true)
              : delete this.errors[key]
          }
        } else if (key === 'links') {
          for (const index in this.item[key]) {
            !this.item[key][index].title
              ? (this.errors[key + '-' + index + '-title'] = true)
              : delete this.errors[key + '-' + index + '-title']
            !this.item[key][index].url
              ? (this.errors[key + '-' + index + '-' + 'url'] = true)
              : delete this.errors[key + '-' + index + '-' + 'url']
          }
        } else if (key === 'subLinks') {
          // if (this.item[key] && this.item[key].length) {
          //   delete this.errors[key]
          for (const index in this.item[key]) {
            !this.item[key][index].name
              ? (this.errors[key + '-' + index + '-name'] = true)
              : delete this.errors[key + '-' + index + '-name']
            !this.item[key][index].url
              ? (this.errors[key + '-' + index + '-' + 'url'] = true)
              : delete this.errors[key + '-' + index + '-' + 'url']
          }
          // } else {
          //   this.errors[key] = true
          // }
        } else if (subStringArrays.indexOf(key) !== -1) {
          if (key === 'emails' && !(this.item[key] && this.item[key].length)) {
            this.errors[key] = true
          } else {
            delete this.errors[key]
            for (const index in this.item[key]) {
              !this.item[key][index]
                ? (this.errors[key + '-' + index] = true)
                : delete this.errors[key + '-' + index]
            }
          }
        } else if (key === 'categories') {
          !(this.item.categoryId && this.item.categoryId.length)
            ? (this.errors[key] = true)
            : delete this.errors[key]
        } else if (this.needValidation(key)) {
        } else {
          !this.item[key] || !this.item[key].trim()
            ? (this.errors[key] = true)
            : delete this.errors[key]
        }
      }
      delete this.errors.__v
      if (Object.keys(this.errors).length) {
        this.$forceUpdate()
        return false
      }
      return true
    },
    // if there are files send them to backend
    async uploadFiles () {
      for (const file in this.files) {
        if (this.files[file]) {
          const data = new FormData()
          data.append('file', this.files[file])
          try {
            const res = await HTTP.post('/files', data)
            this.item[file] = res.data.url
          } catch (error) {
            console.log(error)
            return false
          }
          data.delete('file')
        }
      }
      return true
    },
    // adds data to table after checking for requirements
    async addData () {
      const uploaded = await this.uploadFiles()
      if (this.checkValidation() && uploaded) {
        if (this.$route.name === 'Events') {
          this.item.startDate = this.dateToString(this.dates.start)
          this.item.endDate = this.dateToString(this.dates.end)
        }
        try {
          const res = await this.requests.addOne(this.item, this.lang)
          this.$store.commit('setChanged', true)
          this.$emit('close')
          this.$store.commit('setCurrentItem', res.data)
        } catch (error) {
          console.log(error)
        }
      }
    },
    dateToString (date) {
      date = new Date(date)
      const zero = date.getDate() < 10 ? '0' : ''
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        zero +
        date.getDate()
      )
    },
    // edits selected item after checking for requirements
    async changeData () {
      const uploaded = await this.uploadFiles()
      if (this.checkValidation() && uploaded) {
        const id = this.item._id
        if (this.$route.name === 'Events') {
          this.item.startDate = this.dateToString(this.dates.start)
          this.item.endDate = this.dateToString(this.dates.end)
        }
        if (this.$route.name === 'News') {
          this.item.createdAt = this.dateToString(this.item.createdAt)
        }
        delete this.item._id
        delete this.item.__v
        try {
          await this.requests.update(this.item, id).then((res) => {
            this.$store.commit('setChanged', true)
            this.$emit('close')
          })
        } catch (error) {
          this.item._id = id
          console.log(error)
        }
      }
    }
  },
  beforeMount () {
    if (this.$route.name === 'News') {
      this.getCategories('news')
      if (this.$store.state.modalState.type === 'add') {
        this.item.createdAt = Date.now()
      }
    } else if (this.$route.name === 'Blogs') {
      this.getCategories('blogs')
    } else if (
      this.$route.name === 'Events' &&
      this.$store.state.modalState.type !== 'add'
    ) {
      this.dates.start = new Date(
        this.item.startDate.substr(0, 10).split('-').join(',')
      )
      this.dates.end = new Date(
        this.item.endDate.substr(0, 10).split('-').join(',')
      )
    }
    // !avoid mutating prop directly
    this.keys = this.keys.filter((x) => x !== 'endDate')
  },
  beforeCreate () {},
  computed: {
    // lodash helper function
    _ () {
      return _
    }
  }
}
</script>

<style>
</style>
